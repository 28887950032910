import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { useViewportScroll } from 'framer-motion'
import { Link as LinkScroll, scroller } from 'react-scroll'

// Hooks
import useScrollDirection from '../hooks/useScrollDirection'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

// Assets
import ArrowDown from '../images/arrow-down.inline.svg'
import Pattern from '../images/greek-element-half-column.inline.svg'

// Styles
import '../styles/pages/food.scss'
import Slider, { CurrentSlide, MobileNav, NextSlide } from '../components/slider'

// const NavItem = ({ isPassedNav }) => {}
function scrollTo(elementId, offset) {
  scroller.scrollTo(elementId, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset,
  })
}

const Nav = ({ categories, currentCategoryId, setCurrentCategoryId }) => {
  const [isPassedNav, setIsPassedNav] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const scrollDirection = useScrollDirection()
  const { scrollY } = useViewportScroll()
  useEffect(
    () =>
      scrollY.onChange(v => {
        setIsPassedNav(v > 350)
      }),
    [scrollY],
  )
  const currentCategory = categories.find(category => category.id === currentCategoryId)
  return (
    <ul
      style={{
        top: scrollDirection === 'down' ? 0 : 111,
        letterSpacing: '1px',
      }}
      className="bg-grey-200 px-6 py-10 sticky transition-all text-tuya-slate uppercase text-xs font-semibold space-y-9 md:hidden z-20"
    >
      {isPassedNav && !isOpen ? (
        <li>
          <button
            onClick={() => setIsOpen(true)}
            style={{ letterSpacing: 'inherit' }}
            className="flex items-center uppercase font-semibold w-full"
          >
            <ArrowDown className="mr-4" />
            {currentCategory.title}
          </button>
        </li>
      ) : (
        categories.map((category, i) => (
          <li className="px-7" key={category.id + i}>
            <a
              onClick={() => {
                setIsOpen(false)
                setCurrentCategoryId(category.id)
                scrollTo(category.slug, -220)
              }}
              href={`#${category.slug}`}
            >
              {category.title}
            </a>
          </li>
        ))
      )}
    </ul>
  )
}

const NavDesktop = ({ categories, currentCategoryId, setCurrentCategoryId }) => {
  return (
    <div className="absolute h-full top-14 right-0 nav-wrapper z-20 hidden md:block xl:h-auto">
      <div className="sticky top-14">
        <ul className="bg-white space-y-4 pl-8 py-12 mb-32">
          {categories.map((category, i) => (
            <li
              style={{ letterSpacing: 0.83 }}
              className={`text-xxs font-semibold uppercase ${
                category.id === currentCategoryId ? 'text-tuya-epsilon' : 'text-tuya-mu'
              }`}
              key={category.id + i}
            >
              <LinkScroll
                to={category.slug}
                spy={true}
                smooth={true}
                duration={500}
                hashSpy
                className="cursor-pointer"
                onSetActive={to => setCurrentCategoryId(category.id)}
              >
                {category.title}
              </LinkScroll>
            </li>
          ))}
        </ul>
        <div className="h-full w-full reservation flex justify-end items-center lg:justify-center ">
          <a href="/reservation">
            <Button isDark isVertical>
              RESERVATION
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

const Section = ({ category: { slug, items, title: categoryTitle }, customClass }) => {
  const menuItems = JSON.parse(items)

  return (
    <section className={`mt-8 md:mt-20 ${customClass}`} id={slug}>
      <div className="menu-container">
        <h3 className="text-tuya-gamma text-2xl font-light inline-flex border-b pb-2 border-tuya-mu mb-5 xl:mb-10 xl:font-thinish xl:text-4xl">
          {categoryTitle}
        </h3>
        <ul className="category-list">
          {menuItems.map(({ id, title, subtitle, price, textBetweenBrackets, addon, addon_price }, idx) => (
            <li key={id + title}>
              <div className="tuya-zeta flex flex-col">
                <div className="flex flex-row font-normal">
                  <div>{title}</div>
                  <div className="font-light ml-3">{price} &euro;</div>
                </div>
                {subtitle && (
                  <div className="flex flex-col text-sm italic text-tuya-mu">
                    <div>{subtitle}</div>
                    {addon && (
                      <div>
                        {addon}
                        <span className="ml-4 ">{addon_price} &euro;</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
Section.defaultProps = {
  customClass: '',
}
const HeroSection = ({ image }) => {
  return (
    <section className="bg-black hero-section relative">
      <div className="img-wrapper w-full h-full absolute top-0 left-0 z-1">
        <img
          className="w-full h-full object-cover opacity-50"
          src={image.sizes.src}
          srcSet={image.sizes.srcSet}
          alt="Food Menu"
        />
      </div>
      <div className="z-10 relative px-8 md:px-0 pb-11 md:max-w-2xl lg:max-w-screen-lg md:mx-auto h-full flex items-end">
        <div>
          <h1 className="text-tuya-yellow text-6xl md:text-8xl lg:9xl">Food Menu</h1>
        </div>
      </div>
    </section>
  )
}

const FoodPage = ({ data, location }) => {
  const { categories: categoriesData, heroImage, sliderStarters, sliderMainCourse } = data.datoCmsFood
  const [categories, setCategories] = useState([])
  const [currentCategoryId, setCurrentCategoryId] = useState(null)
  useEffect(() => {
    categoriesData.map(category => (category.slug = category.title.toLowerCase().split(' ').join('-')))
    setCategories(categoriesData)
    setCurrentCategoryId(categoriesData[0].id)
  }, [categoriesData])

  if (!categories.length) return <></>
  return (
    <Layout className="food-page" pathname={location.pathname}>
      <SEO title="Food" />
      <HeroSection image={heroImage} />
      <div className="relative">
        <Pattern className="hidden top-pattern absolute" />
        <NavDesktop
          categories={categories}
          currentCategoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
        />
        <Nav
          categories={categories}
          currentCategoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
        />
        {categories.map((category, idx) => (
          <>
            {idx === 0 && (
              <div className="section-gallery-left">
                
                <div className="hidden xl:block"></div>
                <Section
                  key={category.id}
                  category={category}
                  customClass="xl:ml-32 xl:col-start-4 xl:row-start-1 3xl:ml-40 3xl:mt-24"
                />
              </div>
            )}
            {idx === 1 && (
              <div className="section-gallery-right">
                <Section
                  key={category.id}
                  category={category}
                  customClass="xl:row-start-1 xl:col-start-1 xl:ml-64 3xl:mt-0 3xl:ml-[480px] 4xl:ml-[935px]"
                />
                
                <div className="hidden xl:block col-start-3 xl:row-start-1"></div>
              </div>
            )}
            {idx > 1 && (
              <Section
                key={category.id}
                category={category}
                customClass="xl:ml-64 3xl:ml-[480px] 3xl:mt-24 4xl:mt-38 4xl:ml-[935px]"
              />
            )}
          </>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FoodQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsFood {
      heroImage {
        sizes {
          srcSet
          src
        }
      }
      categories {
        title
        id
        items
      }
      sliderStarters {
        id
        title
        image {
          height
          sizes {
            src
            srcSet
          }
        }
      }
      sliderMainCourse {
        id
        title
        image {
          height
          sizes {
            src
            srcSet
          }
        }
      }
    }
  }
`

export default FoodPage
